'use client';

import { gqlClient } from '@app/gqlClient';
import { useDisclosure } from '@chakra-ui/react';
import { NotificationsDocument, UnreadDocument } from '@gql/graphql';
import { useQuery } from '@tanstack/react-query';
import React from 'react';
import type { NotificationContext as NotificationContextType } from './NotificationContext';
import { NotificationContext } from './NotificationContext';

const SECOND = 1000;

export function NotificationProvider({ children }: React.PropsWithChildren) {
    const { open, onClose, onOpen } = useDisclosure();
    const [page, setPage] = React.useState(0);

    const { data, isLoading, refetch } = useQuery({
        queryKey: [NotificationsDocument],
        refetchInterval: 10 * SECOND,
        queryFn: async () => {
            const gql = await gqlClient();
            return await gql.request(NotificationsDocument, {
                options: {
                    limit: 10,
                    page,
                },
            });
        },
    });

    const { data: countData, refetch: refetchCounts } = useQuery({
        queryKey: [UnreadDocument],
        refetchInterval: 10 * SECOND,
        queryFn: async () => {
            const gql = await gqlClient();
            return await gql.request(UnreadDocument);
        },
    });

    const count = countData?.unread?.count ?? 0;

    const value: NotificationContextType = React.useMemo(() => {
        return {
            actions: {
                open: onOpen,
                close: onClose,
                nextPage: () => setPage((p) => p + 1),
                prevPage: () => setPage((p) => Math.min(p - 1, 0)),
                refetch: () => {
                    refetch();
                    refetchCounts();
                },
            },
            notifications: data?.notifications,
            unreadCount: count,
            isOpen: open,
            isLoading,
        };
    }, [onOpen, onClose, data?.notifications, count, open, isLoading, refetch, refetchCounts]);

    return <NotificationContext.Provider value={value}>{children}</NotificationContext.Provider>;
}
