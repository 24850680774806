'use client';

import { gqlClient } from '@app/gqlClient';
import { Box, Button, CardBody, CardFooter, CardRoot, Circle, Flex, IconButton, Text } from '@chakra-ui/react';
import { Unarray } from '@common/types';
import { DeleteNotificationDocument } from '@connect-core/connect-sdk';
import { MarkAsReadDocument, NotificationActionType, NotificationsQuery } from '@gql/graphql';
import { useMutation } from '@tanstack/react-query';
import { formatDistance } from 'date-fns';
import { useRouter } from 'next/navigation';
import { MdDelete } from 'react-icons/md';
import { useNotificationContext } from './NotificationContext';

type NotificationItemProps = {
    notification: Unarray<NotificationsQuery['notifications']['items']>;
};

export function NotificationItem({ notification }: NotificationItemProps) {
    const router = useRouter();

    const {
        actions: { close, refetch },
    } = useNotificationContext();

    const { mutateAsync: deleteNotification } = useMutation({
        mutationFn: async (id: string) => {
            const gql = await gqlClient();
            return await gql.request(DeleteNotificationDocument, { id });
        },
    });

    const { mutateAsync: markAsRead } = useMutation({
        mutationFn: async (id: string) => {
            const gql = await gqlClient();
            return await gql.request(MarkAsReadDocument, { id });
        },
    });

    if (!notification) {
        return null;
    }

    const getButtonColor = (type: NotificationActionType) => {
        switch (type) {
            case NotificationActionType.Danger:
                return 'red';
            case NotificationActionType.Success:
                return 'green';
            default:
                return 'brand';
        }
    };

    return (
        <CardRoot
            variant={notification.isRead ? 'subtle' : 'outline'}
            _hover={{
                bg: 'gray.100',
                cursor: 'pointer',
            }}
        >
            <CardBody>
                <Flex gap={2} direction="column">
                    <Flex justifyContent="space-between" alignItems="center">
                        <Flex gap={2} alignItems="center">
                            {!notification.isRead ? (
                                <Circle bg="green.500" size={2} outline="0.2em solid" outlineColor="bg" />
                            ) : null}
                            <Text fontWeight="medium">{notification.subject}</Text>
                        </Flex>
                        <Flex>
                            <Text fontSize="xs" color="gray.500">
                                {formatDistance(notification.createdAt, new Date())} ago
                            </Text>
                        </Flex>
                    </Flex>
                    <Box mt={2}>{notification.plainContent}</Box>
                </Flex>
            </CardBody>
            <CardFooter>
                <Flex w="full" gap={2} justifyContent="space-between">
                    <IconButton
                        variant="destructive"
                        size="xs"
                        onClick={async () => {
                            await deleteNotification(notification.id);
                            refetch();
                        }}
                    >
                        <MdDelete />
                    </IconButton>
                    <Flex gap={4}>
                        {notification.actions.map((action, i) => (
                            <Button
                                size="xs"
                                key={i}
                                colorPalette={getButtonColor(action.type)}
                                onClick={async () => {
                                    let rawUrl = action.url;
                                    if (!rawUrl.startsWith('http')) {
                                        // assume this is relative and add the location from window
                                        rawUrl = window.location.origin + rawUrl;
                                    }

                                    const url = new URL(rawUrl);
                                    url.searchParams.append('rand', Math.random().toString(36).substring(7));

                                    await markAsRead(notification.id);
                                    router.push(url.toString());
                                    close();
                                }}
                            >
                                {action.title}
                            </Button>
                        ))}
                    </Flex>
                </Flex>
            </CardFooter>
        </CardRoot>
    );
}
