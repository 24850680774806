import { MenuContent, MenuItem, MenuRoot, MenuTrigger } from '@/components/ui/menu';
import { MenuContentProps, MenuItemProps, MenuRootProps, MenuTriggerProps, Portal } from '@chakra-ui/react';
import Link from 'next/link';

function CtMenuRoot(props: MenuRootProps) {
    return <MenuRoot lazyMount {...props} />;
}

function CtMenuList(props: MenuContentProps) {
    /* 
        browser warning can be ignored as any wrapper around popovers like via Chakra may cause strict semantic html warnings 
        example if used inside table: validateDOMNesting(...): <span> cannot appear as a child of <tbody>. - Chakra creates an empty span, not sure why.
        See: https://github.com/chakra-ui/chakra-ui/issues/6227
     */
    return (
        <Portal>
            <MenuContent {...props} />
        </Portal>
    );
}

function CtMenuTrigger({ ...props }: MenuTriggerProps) {
    return <MenuTrigger asChild {...props} />;
}

function CtMenuButton(props: MenuItemProps) {
    return <MenuItem fontSize="0.875rem" fontWeight="500" {...props} />;
}

interface CtMenuLinkProps {
    href: string;
}
function CtMenuLink({ href, ...props }: MenuItemProps & CtMenuLinkProps) {
    return (
        <Link href={href || '#'}>
            <MenuItem as="div" fontSize="0.875rem" fontWeight="500" {...props} />
        </Link>
    );
}

export const CtMenu = Object.assign(CtMenuRoot, {
    List: CtMenuList,
    Trigger: CtMenuTrigger,
    Button: CtMenuButton,
    Link: CtMenuLink,
});
