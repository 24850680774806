import { Tooltip } from '@/components/ui/tooltip';
import { Box, Flex, IconButton, useDisclosure } from '@chakra-ui/react';
import { useSidebarContext } from '@common/context/sidebar-context';
import React from 'react';
import { FaChevronDown as ChevronDownIcon, FaChevronUp as ChevronUpIcon } from 'react-icons/fa';

export type NavFolderProps = {
    icon: React.ReactElement;
    label: string;
    defaultIsOpen?: boolean;
    hoverColor?: string;
    activeColor?: string;
    tooltip?: React.ReactNode;
    active: boolean;
};

export function NavFolder({
    icon,
    label,
    children,
    defaultIsOpen = false,
    hoverColor = '{colors.nav.hover.parent}',
    activeColor = '{colors.nav.active.parent}',
    tooltip,
    active,
}: React.PropsWithChildren<NavFolderProps>) {
    const { open, onToggle } = useDisclosure({ defaultOpen: defaultIsOpen });
    const [hovered, setHovered] = React.useState(false);
    const { isSidebarExpanded } = useSidebarContext();

    const handleMouseEnter = (_event: React.MouseEvent) => {
        if (isSidebarExpanded) {
            return;
        }

        setHovered(true);
    };

    const handleMouseLeave = (event: React.MouseEvent) => {
        if (isSidebarExpanded) {
            return;
        }

        const boundingRect = event.currentTarget.getBoundingClientRect();
        const mouseX = event.clientX;
        const mouseY = event.clientY;
        const leftBoundary = boundingRect.left + 2;
        const topBoundary = boundingRect.top + 2;
        const bottomBoundary = boundingRect.bottom - 2;

        if (mouseY <= topBoundary || mouseY >= bottomBoundary || mouseX <= leftBoundary) {
            setHovered(false);
        }
    };

    return (
        <Flex direction="column" w="full">
            <Flex
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                onClick={onToggle}
                cursor="pointer"
                w="full"
                px={2}
                minHeight="2.75rem"
                userSelect="none"
                rounded="md"
                transition="all 0.2s"
                _hover={{ bg: active ? undefined : hoverColor, color: 'white' }}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                bgColor={active ? activeColor : undefined}
                color={`{${active ? 'white' : 'colors.nav.bar.fg'}}`}
            >
                <Flex gap="1" alignItems="center">
                    <Box px="1">
                        <Tooltip openDelay={0} content={tooltip} disabled={!tooltip} showArrow={true}>
                            {icon}
                        </Tooltip>
                    </Box>
                    {isSidebarExpanded && label}
                    {!isSidebarExpanded && (
                        <>
                            <Box hidden={!hovered} position="absolute" left="100%" color="{colors.nav.bar.bg}">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="32"
                                    className="bi bi-google"
                                    viewBox="0 0 16 16"
                                >
                                    <path
                                        d="M8 16L8 0L0.707106 7.29289C0.316582 7.68342 0.316582 8.31658 0.707106 8.70711L8 16Z"
                                        fill="currentColor"
                                    />
                                </svg>
                            </Box>
                            <Box
                                hidden={!hovered}
                                bg="{colors.nav.bar.bg}"
                                direction="column"
                                left="114%"
                                position="absolute"
                                width="40"
                                p="4"
                                borderTopRightRadius={8}
                                borderBottomRightRadius={8}
                                zIndex="popover"
                                onMouseLeave={() => {
                                    setHovered(false);
                                }}
                            >
                                <Flex>{children}</Flex>
                            </Box>
                        </>
                    )}
                </Flex>
                <IconButton
                    hidden={!isSidebarExpanded}
                    bg="transparent"
                    border="none"
                    color="currentColor"
                    size="xs"
                    onClick={onToggle}
                    aria-label={open ? 'close' : 'open'}
                >
                    {open ? <ChevronUpIcon /> : <ChevronDownIcon />}
                </IconButton>
            </Flex>
            {isSidebarExpanded && (
                <Flex pl={4} w="full">
                    {open && children}
                </Flex>
            )}
        </Flex>
    );
}
