'use client';

import { Flex, VStack } from '@chakra-ui/react';
import { ConnectAiLogo } from '@common/Logo';
import { Can } from '@common/context/ability-context';
import { ROUTES } from '@root/app/routes';
import { NavButton } from '@routes/_components/NavButton';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { usePathname } from 'next/navigation';
import {
    MdCalendarViewMonth,
    MdCalendarViewWeek,
    MdGridOn,
    MdOutlineAddToPhotos,
    MdOutlineDashboard,
    MdOutlineFileCopy,
    MdOutlineHandshake,
    MdOutlineInterests,
    MdOutlinePeople,
    MdSettings,
} from 'react-icons/md';
import { NavFolder } from './NavFolder';
import { NavItem } from './NavItem';

export function SidebarContent({ expanded = false }) {
    const flags = useFlags();

    const showTpm = flags['tradeModule'] ?? false;
    const showDemand = flags['demandModule'] ?? false;
    const pathname = usePathname();
    const pathSegments = pathname.split('/').filter(Boolean);
    const mainPath = '/' + pathSegments[0];
    const childPath = '/' + pathSegments[0] + '/' + pathSegments[1];

    return (
        <Flex
            h="full"
            direction="column"
            px={1}
            gap={8}
            alignContent="center"
            justifyContent="center"
            w={expanded ? 'var(--sidebar-width)' : 'var(--sidebar-width-not-expanded)'}
            color="{colors.nav.bar.fg}"
        >
            <ConnectAiLogo showText={expanded} backgroundColor="black" fill="#FAFAFA" />
            <Flex direction="column" flex="1" overflow="auto" gap={1}>
                <NavItem
                    icon={<MdOutlineDashboard title="Dashboard" size={24} />}
                    label="Dashboard"
                    href={ROUTES.DASHBOARD.INDEX}
                    tooltip={!expanded && 'Dashboard'}
                    showLabel={expanded}
                    active={mainPath === ROUTES.DASHBOARD.INDEX}
                    activeColor="{colors.nav.active.parent}"
                />
                <NavFolder
                    label="Documents"
                    icon={<MdOutlineFileCopy title="Documents" size={24} />}
                    tooltip={!expanded && 'Documents'}
                    active={mainPath === ROUTES.TEMPLATES.ROOT}
                >
                    <VStack w="full" pt={1} gap={1}>
                        <NavItem
                            icon={<MdOutlineAddToPhotos title="Create New" size={24} />}
                            label="Create New"
                            href={ROUTES.TEMPLATES.GENERATE_NEW}
                            active={childPath === ROUTES.TEMPLATES.GENERATE_NEW}
                        />
                        <Can I="manage" a="UploadedDocument">
                            <NavItem
                                icon={<MdOutlineFileCopy title="Templates" size={24} />}
                                label="Templates"
                                href={ROUTES.TEMPLATES.CUSTOMER}
                                active={childPath === ROUTES.TEMPLATES.CUSTOMER}
                            />
                        </Can>
                    </VStack>
                </NavFolder>
                <Can I="read" a="Customer">
                    <NavItem
                        icon={<MdOutlinePeople title="Customers" size={24} />}
                        label="Customers"
                        href={ROUTES.MASTER_DATA.CUSTOMERS}
                        tooltip={!expanded && 'Customers'}
                        showLabel={expanded}
                        active={mainPath === ROUTES.MASTER_DATA.CUSTOMERS}
                        activeColor="{colors.nav.active.parent}"
                    />
                </Can>
                <Can I="read" a="ProductSearch">
                    <NavItem
                        icon={<MdOutlineInterests title="Products" size={24} />}
                        label="Products"
                        href={ROUTES.MASTER_DATA.PRODUCTS}
                        tooltip={!expanded && 'Products'}
                        showLabel={expanded}
                        active={mainPath === ROUTES.MASTER_DATA.PRODUCTS}
                        activeColor="{colors.nav.active.parent}"
                    />
                </Can>
                {showTpm && (
                    <NavFolder
                        label="Trade"
                        icon={<MdOutlineHandshake title="Trade" size={24} />}
                        tooltip={!expanded && 'Trade planning'}
                        active={mainPath === ROUTES.TRADE_PLANNING.PLANS}
                    >
                        <VStack w="full" pt={1} gap={1}>
                            <NavItem
                                icon={<MdOutlineAddToPhotos title="Create New" size={24} />}
                                label="Create new"
                                href={ROUTES.TRADE_PLANNING.CREATE}
                                active={childPath === ROUTES.TRADE_PLANNING.CREATE}
                            />
                            <NavItem
                                icon={<MdCalendarViewWeek title="Calendar" size={24} />}
                                label="Calendar"
                                href={ROUTES.TRADE_PLANNING.CALENDAR}
                                active={childPath === ROUTES.TRADE_PLANNING.CALENDAR}
                            />
                            <NavItem
                                icon={<MdCalendarViewMonth title="Trade Plans" size={24} />}
                                label="Trade plans"
                                href={ROUTES.TRADE_PLANNING.TRADE_PLANS}
                                active={childPath === ROUTES.TRADE_PLANNING.TRADE_PLANS}
                            />
                        </VStack>
                    </NavFolder>
                )}
                {showDemand && (
                    <Can I="manage" a="DemandPlan">
                        <NavFolder
                            label="Demand"
                            icon={<MdGridOn title="Demand" size={24} />}
                            tooltip={!expanded && 'Demand planning'}
                            active={mainPath === ROUTES.DEMAND_PLANNING.PLANS}
                        >
                            <VStack w="full" pt={1} gap={1}>
                                <NavItem
                                    icon={<MdOutlineAddToPhotos title="Create New" size={24} />}
                                    label="Create new"
                                    href={ROUTES.DEMAND_PLANNING.CREATE_NEW}
                                    active={childPath === ROUTES.DEMAND_PLANNING.CREATE_NEW}
                                />
                                <NavItem
                                    icon={<MdCalendarViewWeek title="Current" size={24} />}
                                    label="Current"
                                    href={ROUTES.DEMAND_PLANNING.CURRENT_FORECAST.ROOT}
                                    active={childPath === ROUTES.DEMAND_PLANNING.CURRENT_FORECAST.ROOT}
                                />
                                <NavItem
                                    icon={<MdCalendarViewMonth title="Drafts" size={24} />}
                                    label="Drafts"
                                    href={ROUTES.DEMAND_PLANNING.PLANS}
                                    active={childPath === ROUTES.DEMAND_PLANNING.PLANS}
                                />
                                <Can I="manage" a="ForecastMethodology">
                                    <NavItem
                                        icon={<MdSettings title="Forecast Methodology" size={24} />}
                                        label="Forecast"
                                        href={ROUTES.DEMAND_PLANNING.ADMIN.ROOT}
                                        active={childPath === ROUTES.DEMAND_PLANNING.ADMIN.ROOT}
                                    />
                                </Can>
                            </VStack>
                        </NavFolder>
                    </Can>
                )}
            </Flex>
            <NavButton expanded={expanded} />
        </Flex>
    );
}
