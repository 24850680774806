import { Box } from '@chakra-ui/react';

type LogoProps = {
    variant?: 'full' | 'minimal';
    backgroundColor?: string;
    fill?: string;
    showText?: boolean;
};

export const ConnectAiLogo = ({
    variant = 'full',
    backgroundColor = '#ebebeb',
    fill = '#000000',
    showText = false,
}: LogoProps) => {
    const isMinimal = variant === 'minimal';
    const wrapperStyles = isMinimal
        ? {
              display: 'flex',
              justifyContent: 'center',
          }
        : { display: 'flex', alignItems: 'center' };

    return (
        <Box style={wrapperStyles}>
            <Box
                backgroundColor={backgroundColor}
                style={{
                    display: 'inline-block',
                    borderRadius: '4px',
                }}
            >
                {showText && variant === 'full' ? (
                    <svg width="176" height="32" viewBox="0 0 176 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="176" height="31.9091" rx="0" fill={backgroundColor} />
                        <path
                            d="M89.5454 10.3097C86.4404 10.3097 83.9136 12.8365 83.9136 15.9415C83.9136 19.0465 86.4404 21.5733 89.5454 21.5733C92.6504 21.5733 95.1771 19.0465 95.1771 15.9415C95.1771 12.8365 92.6504 10.3097 89.5454 10.3097ZM89.5454 24.4036C84.8792 24.4036 81.0818 20.6077 81.0818 15.9415C81.0818 11.2753 84.8792 7.47937 89.5454 7.47937C94.2115 7.47937 98.0075 11.2753 98.0075 15.9415C98.0075 20.6077 94.2115 24.4036 89.5454 24.4036Z"
                            fill={fill}
                        />
                        <path
                            d="M70.9663 10.3097C67.8613 10.3097 65.3345 12.8365 65.3345 15.9415C65.3345 19.0465 67.8613 21.5733 70.9663 21.5733C74.0712 21.5733 76.598 19.0465 76.598 15.9415C76.598 12.8365 74.0712 10.3097 70.9663 10.3097ZM70.9663 24.4036C66.3001 24.4036 62.5027 20.6077 62.5027 15.9415C62.5027 11.2753 66.3001 7.47937 70.9663 7.47937C75.6324 7.47937 79.4298 11.2753 79.4298 15.9415C79.4298 20.6077 75.6324 24.4036 70.9663 24.4036Z"
                            fill={fill}
                        />
                        <path
                            d="M105.956 7.93472H99.8267V23.9801H105.956C111.184 23.9801 114 20.8476 114 15.9574C114 11.0672 111.183 7.93472 105.956 7.93472ZM105.909 21.3651H102.867V10.5482H105.909C109.019 10.5482 110.844 12.3956 110.844 15.956C110.844 19.5163 109.02 21.3651 105.909 21.3651Z"
                            fill={fill}
                        />
                        <path
                            d="M122.138 24.34C126.149 24.34 128.628 22.5823 128.628 19.405C128.628 16.858 127.096 15.3936 123.31 14.6492L121.914 14.3789C119.526 13.8831 118.781 13.2976 118.781 12.0126C118.781 10.7954 119.728 10.0293 121.667 10.0293C124.214 10.0293 125.091 10.9978 125.204 12.7107H128.156C128.043 9.69103 126.128 7.57333 121.62 7.57333C117.768 7.57333 115.671 9.44385 115.671 12.1022C115.671 15.0091 117.722 16.3838 120.831 16.9693L122.183 17.2396C124.639 17.7354 125.473 18.2992 125.473 19.5611C125.473 21.0717 124.324 21.8826 122.228 21.8826C119.996 21.8826 118.306 21.2076 118.261 18.6822H115.264C115.288 22.3784 117.856 24.3386 122.138 24.3386"
                            fill={fill}
                        />
                        <path
                            d="M131.646 22.4419L131.444 23.0982C131.409 23.2211 131.373 23.3598 131.34 23.4885C131.308 23.3627 131.271 23.2283 131.233 23.1054L131.029 22.4434H130.481V23.9858H130.83V23.5333C130.83 23.2861 130.828 23.0548 130.82 22.7903C130.85 22.9074 130.88 23.0057 130.922 23.1387L131.188 23.9858H131.487L131.755 23.1387C131.795 23.0071 131.826 22.9059 131.859 22.7889C131.849 23.0534 131.846 23.2876 131.846 23.5347V23.9872H132.194V22.4448H131.646V22.4419ZM130.347 22.4419H129.033V22.7686H129.506V23.9843H129.873V22.7686H130.347V22.4419Z"
                            fill={fill}
                        />
                        <path
                            d="M51.5812 21.5473C48.8014 21.1946 46.6461 18.8153 46.6461 15.943C46.6461 12.8265 49.1816 10.291 52.2981 10.291C54.6413 10.291 56.655 11.7235 57.5122 13.7588H60.5087C59.5417 10.1306 56.2285 7.4491 52.2996 7.4491C47.6161 7.4491 43.8057 11.2595 43.8057 15.943C43.8057 20.3851 47.233 24.0394 51.5841 24.4051V21.5473H51.5812Z"
                            fill={fill}
                        />
                        <path
                            d="M53.0261 15.5584V18.2269L56.2077 18.2413C55.6512 19.7649 54.7449 21.0948 53.0088 21.5501V24.405C55.2522 24.2171 56.8828 22.8598 57.9915 21.024L57.9771 24.46L60.6686 24.4556L60.7048 15.5599H53.0261V15.5584Z"
                            fill={fill}
                        />
                    </svg>
                ) : (
                    <svg width="46" height="33" viewBox="0 0 46 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="46" height="32.0471" rx="0" fill={backgroundColor} />
                        <path
                            d="M22.4154 21.6592C19.587 21.3053 17.3924 18.9203 17.3924 16.0398C17.3924 12.9151 19.973 10.3729 23.1449 10.3729C25.5295 10.3729 27.5791 11.809 28.4511 13.8497H31.5C30.516 10.2121 27.1437 7.52356 23.1449 7.52356C18.3783 7.52356 14.5 11.3435 14.5 16.0398C14.5 20.4931 17.9891 24.1564 22.416 24.5236V21.6586L22.4154 21.6592Z"
                            fill={fill}
                        />
                        <path
                            d="M23.9621 16.0236V18.5714L27.0852 18.5851C26.5389 20.041 25.6496 21.3106 23.9445 21.7449V24.4716C26.1466 24.2916 27.7484 22.9957 28.8368 21.2426L28.8221 24.5236L31.4642 24.5196L31.5 16.0236H23.9621Z"
                            fill={fill}
                        />
                    </svg>
                )}
            </Box>
        </Box>
    );
};
