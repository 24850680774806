'use client';

import { Avatar } from '@/components/ui/avatar';
import { BreadcrumbCurrentLink, BreadcrumbLink, BreadcrumbRoot } from '@/components/ui/breadcrumb';
import { Flex, Stack, Text } from '@chakra-ui/react';
import { useBreadcrumb } from '@common/context/breadcrumb-context';
import NextLink from 'next/link';
import { MdArrowForwardIos as ChevronRightIcon } from 'react-icons/md';

export const BreadcrumbNavigation = () => {
    const { breadcrumbs } = useBreadcrumb();

    return (
        <Stack>
            <BreadcrumbRoot size="lg" separator={<ChevronRightIcon />}>
                {breadcrumbs.map((breadcrumb, index) => {
                    const isLastBreadcrumb = index === breadcrumbs.length - 1;
                    const missingHref = !breadcrumb.href;
                    const crumb =
                        isLastBreadcrumb || missingHref ? (
                            <BreadcrumbCurrentLink
                                css={{ color: '{colors.gray.50}', fontWeight: 'bold' }}
                                key={breadcrumb.label}
                            >
                                {breadcrumb.image ? (
                                    <Flex direction="row">
                                        <Avatar src={breadcrumb.image} size="2xs" mr="1" />
                                        <Text>{breadcrumb.label}</Text>
                                    </Flex>
                                ) : (
                                    breadcrumb.label
                                )}
                            </BreadcrumbCurrentLink>
                        ) : (
                            <BreadcrumbLink
                                css={{ color: '{colors.gray.50}' }}
                                key={breadcrumb.label}
                                as={NextLink}
                                href={breadcrumb.href}
                            >
                                {breadcrumb.label}
                            </BreadcrumbLink>
                        );

                    return crumb;
                })}
            </BreadcrumbRoot>
        </Stack>
    );
};
