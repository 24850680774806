'use client';

import React from 'react';

/**
 * Represents the flow of navigation or data transfer between different parts of the application.
 * This type is primarily used to manage state and provide context as users navigate between different pages or components.
 *
 * @property {string} origin - The starting point of the flow, which should be the current path.
 * @property {string} destination - Represents the intended path the user is navigating to, from the origin.
 * @property {GsItemCrud | EachCrud} formData - Stores the cached form data from the source page.
 * @property {string} saveButtonText - The text to be displayed on the save button in the destination page.
 * @property {string} cancelButtonText - The text to be displayed on the cancel button in the destination page.
 * @property {string} alertText - The text to be displayed as an inline alert in the destination page.
 * @property {() => void} refetch - A function to trigger a refresh of data relevant to the flow. This ensures that the underlying data is up-to-date when returning to the source page.
 */
export type Flow<T> = {
    origin: string;
    destination: string;
    formData: T;
    saveButtonText: string;
    cancelButtonText: string;
    alertText: string;
    refetch: () => void;
};

/**
 * Represents the context for managing navigation flows in the application.
 * Provides a "stack-like" mechanism for managing flows, allowing components to  push, pop, clear, or replace navigation-related states.
 *
 * @property {() => Flow | undefined} pop - Removes and returns the top entry of the stack.
 * @property {(flow: Flow) => void} push - Adds a new flow entry to the top of the stack.
 * @property {() => void} clear - Clears all entries in the flow stack.
 * @property {() => Flow | undefined} current - Retrieves the current top entry of the stack without removing it.
 * @property {(flow: Flow) => void} replace - Replaces the top entry of the stack with a new flow entry.
 */
type FlowContext = {
    pop: () => Flow<any> | undefined;
    push: (flow: Flow<any>) => void;
    clear: () => void;
    current: Flow<any> | undefined;
    replace: (flow: Flow<any>) => void;
};

export const FlowContext = React.createContext<FlowContext | undefined>(undefined);

export function useFlowContext() {
    const context = React.useContext(FlowContext);

    if (!context) {
        throw new Error('useFlowContext must be used within FlowProviderContext');
    }

    return context;
}

export function FlowProvider({ children }: React.PropsWithChildren) {
    const [flows, setFlows] = React.useState<Flow<any>[]>([]);
    const [current, setCurrent] = React.useState<Flow<any> | undefined>();

    const pop = () => {
        const flowsCopy = [...flows];
        const flow = flowsCopy.pop();

        setFlows(flowsCopy);
        setCurrent(flowsCopy[flowsCopy.length - 1]);

        return flow;
    };

    const push = (flow: Flow<any>) => {
        const flowsCopy = [...flows];
        flowsCopy.push(flow);

        setFlows(flowsCopy);
        setCurrent(flowsCopy[flowsCopy.length - 1]);
    };

    const replace = (flow: Flow<any>) => {
        const flowsCopy = [...flows];
        flowsCopy.pop();
        flowsCopy.push(flow);

        setFlows(flowsCopy);
        setCurrent(flowsCopy[flowsCopy.length - 1]);
    };

    const clear = () => {
        setFlows([]);
        setCurrent(undefined);
    };

    const value: FlowContext = {
        pop,
        push,
        current,
        replace,
        clear,
    };

    return <FlowContext.Provider value={value}>{children}</FlowContext.Provider>;
}
