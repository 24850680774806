'use client';

import { Box, IconButton, Text } from '@chakra-ui/react';
import { useSidebarContext } from '@common/context/sidebar-context';
import { PiCaretLeftBold, PiCaretRightBold } from 'react-icons/pi';
import { SidebarContent } from './SidebarContent';

export default function Sidebar() {
    const { isSidebarExpanded, setIsSidebarExpanded } = useSidebarContext();
    const expandCollapseLabel = `${isSidebarExpanded ? 'Collapse' : 'Expand'} Sidebar`;

    function toggleSidebar() {
        setIsSidebarExpanded(!isSidebarExpanded);
    }

    return (
        <Box
            minHeight="100vh"
            minW={isSidebarExpanded ? 'var(--sidebar-width)' : 'var(--sidebar-width-not-expanded)'}
            bg="gray.900"
            color="white"
            fontSize="sm"
            shadow="md"
            pb="4"
            pt="2"
            style={{ position: 'relative' }}
        >
            <IconButton
                bg="white"
                onClick={toggleSidebar}
                aria-label={expandCollapseLabel}
                title={expandCollapseLabel}
                size="xs"
                borderRadius="full"
                focusRingColor="brandPink.600"
                borderColor="{colors.brandPink.700}"
                borderStyle="solid"
                borderWidth="1px"
                style={{
                    position: 'absolute',
                    top: '50%',
                    right: '-12px',
                    transform: 'translateY(-50%)',
                    zIndex: 1000,
                }}
            >
                <Text color="{colors.brandPink.700}">
                    {isSidebarExpanded ? <PiCaretLeftBold /> : <PiCaretRightBold />}
                </Text>
            </IconButton>
            <SidebarContent expanded={isSidebarExpanded} />
        </Box>
    );
}
