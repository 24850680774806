import React from 'react';

type SidebarType = {
    isSidebarExpanded: boolean;
    setIsSidebarExpanded: (value: boolean) => void;
};

export const SidebarContext = React.createContext<SidebarType>({
    isSidebarExpanded: true,
    setIsSidebarExpanded: () => {
        // intentional no-op: this is just a placeholder
    },
});

export function useSidebarContext() {
    return React.useContext(SidebarContext);
}
