import(/* webpackMode: "eager", webpackExports: ["BreadcrumbNavigation"] */ "/home/runner/work/connect-core/connect-core/apps/web/app/_common/BreadcrumbNavigation.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FlowProvider"] */ "/home/runner/work/connect-core/connect-core/apps/web/app/_common/context/FlowContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NavigationEvents"] */ "/home/runner/work/connect-core/connect-core/apps/web/app/(private)/_components/NavigationEvents.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/connect-core/connect-core/apps/web/app/(private)/_components/Notifications/NotificationDrawer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NotificationProvider"] */ "/home/runner/work/connect-core/connect-core/apps/web/app/(private)/_components/Notifications/NotificationProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/connect-core/connect-core/apps/web/app/(private)/_components/Sidebar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/connect-core/connect-core/apps/web/app/(private)/providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Box"] */ "/home/runner/work/connect-core/connect-core/node_modules/@chakra-ui/react/dist/esm/components/box/box.js");
;
import(/* webpackMode: "eager", webpackExports: ["Flex"] */ "/home/runner/work/connect-core/connect-core/node_modules/@chakra-ui/react/dist/esm/components/flex/flex.js");
