import { Unarray } from '@common/types';
import { NotificationsQuery } from '@gql/graphql';
import React from 'react';

export type Notification = Unarray<NotificationsQuery['notifications']['items']>;

export type NotificationContext = {
    actions: {
        open: () => void;
        close: () => void;
        nextPage: () => void;
        prevPage: () => void;
        refetch: () => void;
    };

    isLoading: boolean;
    isOpen: boolean;
    notifications?: NotificationsQuery['notifications'];
    unreadCount: number;
};

export const NotificationContext = React.createContext<NotificationContext | null>(null);

export function useNotificationContext() {
    const context = React.useContext(NotificationContext);

    if (!context) {
        throw new Error('useNotificationContext must be used within NotificationContext');
    }

    return context;
}
