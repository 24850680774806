'use client';

import { AbilityContext } from '@common/context/ability-context';
import { SidebarContext } from '@common/context/sidebar-context';
import { useAbility } from '@common/hooks/useAbility';
import LDProvider from '@common/providers/LDProvider';
import { useSDK } from '@connect-core/connect-sdk';
import React, { useEffect } from 'react';
import { ConnectEnv } from '../_lib/env';

interface AuthenticatedProvidersProps {
    children: React.ReactNode;
    env: ConnectEnv;
    loadConfig: () => Promise<ConnectEnv>;
}

export default function AuthenticatedProviders({ children, env, loadConfig }: AuthenticatedProvidersProps) {
    const { ability } = useAbility();
    const [isSidebarExpanded, setIsSidebarExpanded] = React.useState(true);
    const [environment, setEnvironment] = React.useState<ConnectEnv>(env);

    const { users } = useSDK();
    const { data: user, isLoading } = users.useUser();

    const handleSidebar = React.useMemo(
        () => ({
            isSidebarExpanded,
            setIsSidebarExpanded,
        }),
        [isSidebarExpanded, setIsSidebarExpanded]
    );

    useEffect(() => {
        if (!env?.api.url) {
            loadConfig().then((env) => {
                setEnvironment(env);
            });
        }
    }, [loadConfig, env]);

    // If the user isn't loaded yet (which is mostly should be),
    // we just don't want to render launch darkly.
    // Launch darkly seems to fail if you try to initialize it with an invalid context
    // and it never recovers.
    if (isLoading) {
        return (
            <AbilityContext.Provider value={ability}>
                <SidebarContext.Provider value={handleSidebar}>{children}</SidebarContext.Provider>
            </AbilityContext.Provider>
        );
    }

    const org = user?.organization;
    const orgContext = org
        ? {
              key: org.id,
              name: org.name,
          }
        : {};

    const userContext = {
        key: user?.email,
        firstName: user?.firstName,
        lastName: user?.lastName,
        roles: user?.roles,
    };

    const ldContext = {
        kind: 'multi',
        org: orgContext,
        user: userContext,
    };

    return (
        <LDProvider clientSideID={environment.launchDarkly.clientSideId} context={ldContext}>
            <AbilityContext.Provider value={ability}>
                <SidebarContext.Provider value={handleSidebar}>{children}</SidebarContext.Provider>
            </AbilityContext.Provider>
        </LDProvider>
    );
}
