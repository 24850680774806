'use client';

import { signOut } from 'next-auth/react';
import { NavButtonProps } from './TopNavigation';

import { Avatar } from '@/components/ui/avatar';
import { SkeletonCircle } from '@/components/ui/skeleton';
import { CtMenu } from '@root/app/_common/CtMenu';
import { cx } from '@root/app/_common/utils';
import { ROUTES } from '@root/app/routes';

import { Badge, Circle, Flex, Float, Text } from '@chakra-ui/react';
import { Can } from '@common/context/ability-context';
import { User, useSDK } from '@connect-core/connect-sdk';
import { ExternalLinkIcon, HiMiniUserCircle, SettingsIcon, WarningTwoIcon } from '@root/app/_common/icons/Icons';
import { MdNotificationImportant } from 'react-icons/md';
import { useNotificationContext } from './Notifications/NotificationContext';

export function NavButton(params: NavButtonProps & { expanded?: boolean }) {
    const { users } = useSDK();
    const { data, isLoading } = users.useUser();
    const user = data as User;
    const image = user?.avatar?.downloadUrl;
    const fullName = `${user?.firstName} ${user?.lastName}`;
    const {
        unreadCount,
        actions: { open },
    } = useNotificationContext();

    const handleSignOut = () => {
        signOut({ callbackUrl: ROUTES.HOME });
    };

    return (
        <CtMenu>
            <CtMenu.Trigger aria-label="Profile" title="Profile" background="transparent">
                {isLoading ? (
                    <SkeletonCircle size="10" />
                ) : (
                    <Flex bg="white" gap="2" alignItems="center">
                        <Avatar
                            bg="transparent"
                            src={image || undefined}
                            name={fullName || 'Account'}
                            paddingTop={cx(!image && '1px')}
                            marginLeft="1"
                            paddingLeft={cx(!image && '1px')}
                            height="10"
                            width="10"
                        />
                        <>
                            {!!unreadCount && (
                                <Float placement="bottom-end" offsetX="5" offsetY="5">
                                    <Circle bg="green.500" size="8px" outline="0.2em solid" outlineColor="bg" />
                                </Float>
                            )}
                            <Flex hidden={!params.expanded} direction="column">
                                <Text fontWeight={400} fontSize={16}>
                                    {fullName}
                                </Text>
                                <Text fontSize={12}>{user?.email}</Text>
                            </Flex>
                        </>
                    </Flex>
                )}
            </CtMenu.Trigger>
            <CtMenu.List>
                <CtMenu.Link fontSize="1rem" fontWeight="400" href={ROUTES.PROFILE} value="nav-to-my-profile">
                    <HiMiniUserCircle />
                    My Profile
                </CtMenu.Link>
                <CtMenu.Link
                    fontSize="1rem"
                    fontWeight="400"
                    href={''}
                    onClick={(e) => {
                        e.preventDefault();
                        open();
                    }}
                    value="nav-to-settings"
                >
                    <MdNotificationImportant />
                    Notifications
                    <Badge hidden={!unreadCount} size="sm" variant="solid" marginLeft="2">
                        {unreadCount ?? 0}
                    </Badge>
                </CtMenu.Link>
                <Can I="update" a="Organization">
                    <CtMenu.Link
                        fontSize="1rem"
                        fontWeight="400"
                        href={params.orgId ? ROUTES.ADMIN.ORGANIZATION.GENERAL(params.orgId) : ROUTES.ADMIN.ROOT}
                        value="nav-to-admin"
                    >
                        <SettingsIcon />
                        Admin
                    </CtMenu.Link>
                </Can>
                <Can I="manage" a="all">
                    <CtMenu.Link
                        fontSize="1rem"
                        fontWeight="400"
                        href={ROUTES.ADMIN.SUPER_USER_TOOLS}
                        value="nav-to-tools"
                    >
                        <WarningTwoIcon />
                        Tools
                    </CtMenu.Link>
                </Can>
                <CtMenu.Button fontSize="1rem" fontWeight="400" onClick={handleSignOut} value="logout">
                    <ExternalLinkIcon />
                    Logout
                </CtMenu.Button>
            </CtMenu.List>
        </CtMenu>
    );
}
