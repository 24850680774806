'use client';

import { GraphQLClient } from 'graphql-request';
import { getSession } from 'next-auth/react';
import { getEnv } from './_lib/env';

export const gqlClient = async () => {
    const token = await getSession();
    const env = await getEnv();

    return new GraphQLClient(`${env.api.url}/graphql`, {
        headers: () => {
            const authHeaders = {
                Authorization: `Bearer ${token?.user?.token}`,
            };

            return {
                'Content-Type': 'application/json',
                ...authHeaders,
            };
        },
    });
};
