'use client';

import { useFlowContext } from '@common/context/FlowContext';
import { usePathname } from 'next/navigation';
import { useEffect } from 'react';

/**
 * Listens for changes in the browser's pathname and performs actions based on the navigation events.
 */
export function NavigationEvents() {
    const pathname = usePathname();
    const { clear, current } = useFlowContext();

    useEffect(() => {
        // If there is a current flow context and the user navigates to a page that is neither the flow's origin nor destination, the flow context is cleared.
        // This ensures that cached flow data does not interfere with unrelated navigation flows.
        if (current && pathname !== current?.origin && pathname !== current?.destination) {
            clear();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);

    return null;
}
